import { Component, OnInit } from '@angular/core';
import { NavBarComponent } from '../nav-bar/nav-bar.component'
import {Title , Meta} from "@angular/platform-browser";
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  blogs: any[];
  arabicBlogs: any[];
  lang: any;
  constructor(private sharedService:SharedService , private meta:Meta ,private titleService:Title , private myNav: NavBarComponent ,public translate :TranslateService) {
    
    this.blogs = [
      {
        id: 1,
        img: "/assets/images/card1.png",
        title: "Five reasons why ConClinic is your first choice for managing your clinic",
        text: "Paper methods have become one of the old and inefficient methods of managing any medical clinic.",
      },
      {
        id: 2,
        img: "/assets/images/card2.png",
        title: "Actions that affect your clinic in a bad way!",
        text: "Managing your clinic efficiently is a difficult challenge, but not impossible"
      },
      {
        id: 3,
        img: "/assets/images/card3.png",
        title: "7 Basic steps to implement a successful management system for your clinic",
        text: "One of the most important steps for the success of your clinic is to apply a good management system"
      },
      {
        id: 4,
        img: "/assets/images/4.png",
        title: "How will the digital transformation process and management systems change in your health facility?",
        text: "Medical management systems have an important role in transforming unsustainable systems into sustainable ones, and providing advanced, less expensive and more effective solutions for management and service delivery, and this in turn will be reflected in your health facility."
      },
      {
        id: 5,
        img: "/assets/images/5.png",
        title: "What are the most common challenges that you may face in implementing medical management systems?",
        text: "Digital transformation may become a major challenge because transferring that paper data may take some time, but we guarantee that you will have an easy access to each data and efficiently provide your medical services after that stage"
      },
      {
        id: 6,
        img: "/assets/images/6.png",
        title: "Problems you need to avoid  when it comes to scheduling your appointments",
        text: "Clinic management systems helped solve many of the problems that were facing clinics, especially those who still rely on the paper system in management and organization"
      },
      {
        id: 7,
        img: "/assets/images/7.jpg",
        title: "How Conclinic system helps you to increase your customer base?",
        text: "Providing a distinguished service to your customers that enables you to gain their trust starts with choosing a program that organizes your tasks and the database of customers, if they are not collected in an organized manner and in a safe place, it will cause many problems and make the sales process more difficult."
      },
      {
        id: 8,
        img: "/assets/images/8.jpg",
        title: "Signs that indicated your need for a strong management system",
        text: "Systems and programs specialized in managing medical facilities  help in the growth and development, and in case you are hesitant whether you need a management system or not"
      }
      ,
      {
        id: 9,
        img: "/assets/images/9.jpg",
        title: "3 important Points that help you build a strong relationship with your customers",
        text: "The value of your company increases with the increase in the number of your permanent and regular customers, and as usual obtaining a new customer or maintaining existing ones is difficult and expensive"
      }
    ]

    
    
    this.arabicBlogs = [
      {
        id: 1,
        img: "/assets/images/card1.png",
        title: "خمس أسباب تجعل  كونكلنك هو اختيارك الأول لإدارة عيادتك",
        text: "الأساليب الورقية أصبحت من الطرق القديمة والغير فعالة في ادارة اي عيادة طبية",
      },
      {
        id: 2,
        img: "/assets/images/card2.png",
        title: "كيف تدير عيادتك بالشكل الامثل",
        text:"تصرفات يجب عليك التخلص منها لأنها قد تضر بعيادتكإدارة عيادتك بكفاءة هو تحدي صعب ولكن ليس مستحيل، وأحيانا قد نحتاج لبعض الإرشاد كي لا نقع في تصرفات تضر ببيئة العمل",
      },
      {
        id: 3,
        img: "/assets/images/card3.png",
        title: "7 خطوات لتطبيق نظام إدارة ناجح لعيادتك",
        text:" من أهم خطوات نجاح عيادتك هو تطبيق نظام ادارة جيد، وتطبيق نظام ادارة جيد تحتاج لاتباع بعض الخطوات الأساسية لتضمن أكبر استفادة"
      },
      {
        id: 4,
        img: "/assets/images/4.png",
        title: "كيف ستغير عملية التحول الرقمي وأنظمة الإدارة في منشأتك الصحية",
        text: "نُظُم إدارة المنشآت الطبية في القطاع الطبي لها دور مهم في تحويل الأنظمة الغير مستدامة إلى أنظمة مستدامة، وتوفير حلول متطورة وأقل تكلفة وأكثر فعالية للإدارة وتقديم الخدمات، وهذا بدوره سوف ينعكس منشأتك الصحية "
      },
      {
        id: 5,
        img: "/assets/images/5.png",
        title: "ما هي أهم التحديات التي قد تواجهك في تطبيق نُظم الإدارة لمنشأتك الطبية",
        text: "التحول الرقمي قد يصبح تحدي كبير لان نقل تلك البيانات الورقية قد يستهلك بعض الوقت ولكن نضمن لك الحصول على النظام والفعالية في تقديم خدماتك الطبية بعد تلك المرحلة"
      },
      {
        id: 6,
        img: "/assets/images/6.png",
        title: "مشاكل يجب عليك تجنبها في جدولة مواعيد عيادتك",
        text: "نُظُم إدارة العيادات ساعدت في حل الكثير من المشاكل التى كانت تواجه العيادات التي مازالت تعتمد على النظام الورقي في الإدارة والتنظيم "
      },
      {
        id: 7,
        img: "/assets/images/7.jpg",
        title: "كيف يُمكنك نظام كونكلنك من زيادة عدد عملائك؟",
        text: "تقديم خدمة مميزة لعملائك يمكنك من كسب ثقتهم وكسب عملاء جدد , ومن أكثر الأشياء التى تساعدك على ذلك هو اختيار برنامج ينظم لك مهام عملك وجميع التفاصيل الخاصة بالعملاء حيث انه إذا لم يتم تسجيلها بشكل منظم وفي مكان آمن سوف يسبب مشاكل عديدة ويزيد خطوات عملية البيع صعوبة "
      },
      {
        id: 8,
        img: "/assets/images/8.jpg",
        title: "علامات لو تواجدت في منشأتك الطبية، اذاً انت بحاجة لنظام إدارة قوي",
        text: "الانظمة والبرامج المتخصصة في إدارة العيادات تساعد في النمو والتطوير من أمنشأتك الطبية"
      },
      {
        id: 9,
        img: "/assets/images/9.jpg",
        title: "٣ نقاط تساعدك على بناء علاقة قوية بعملائك",
        text: "قيمة شركتك تزداد كل ما زاد عدد عملائك الدائمين والمنتظمين، ولان عادة الحصول عل عميل جديد او الحفاظ على العلماء الحاليين أمر صعب ومكلف، نقدم لك بعض النصائح التي ستساعدك في الحفاظ على عملائك"
      }
    ]


    this.lang = this.myNav.myLang;
    console.log(this.lang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('BlogsDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('BlogsTitle'))
  })
  }

 
  ngOnInit(): void {
 
    
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('BlogsDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('BlogsTitle'))
 
}

}
