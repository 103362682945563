import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUserComponent } from './add-user/add-user.component';
import { UserComponent } from './user/user.component';


const routes: Routes = [
  { path: "best-clinic-management-systems", component: UserComponent },
  { path: "Add-User", component: AddUserComponent },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExternalUsersRoutingModule { }
