<form [formGroup]="doctorForm" novalidate style="text-align: center;">
  <div class="form-group position-relative">
      <input type="email" placeholder="Email" class="form-control" formControlName="Email" style="border-radius: 12.5px;"/>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="12" r="4" />
        <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28" />
      </svg>
  </div>
  <div
    *ngIf="
      doctorForm.controls['Email'].invalid &&
      (doctorForm.controls['Email'].dirty || doctorForm.controls['Email'].touched)
    "
    class="alert alert-danger"
  >
    <div *ngIf="doctorForm.controls['Email'].hasError('required')">
      {{ "login.reqEmail" | translate }}
    </div>
  </div>
  <div class="form-group position-relative">
    <input type="password" placeholder="Password" class="form-control" formControlName="Password" style="border-radius: 12.5px;"/>
    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-open" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <rect x="5" y="11" width="14" height="10" rx="2" />
      <circle cx="12" cy="16" r="1" />
      <path d="M8 11v-5a4 4 0 0 1 8 0" />
    </svg>
</div>
<div
  *ngIf="
    doctorForm.controls['Password'].invalid &&
    (doctorForm.controls['Password'].dirty || doctorForm.controls['Password'].touched)
  "
  class="alert alert-danger"
>
  <div *ngIf="doctorForm.controls['Password'].hasError('required')">
    {{ "login.reqPass" | translate }}
  </div>
</div>
<div class="mb-3">
  <a class="txtDire  text-white pointer forgot-password" data-toggle="modal" data-target="#forgetPasswordPopup">
    {{ 'ForgetPassword' | translate}}
</a>
<br />
</div>
<button class="btn" style="background: #f2a000; color: white; text-transform: none;"  type="submit" [disabled]='!doctorForm.valid' (click)="login()">
  {{ "login.signIn" | translate }}
</button>
</form>
<div id="forgetPasswordPopup" class="modal " role="dialog">
  <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
          <div class="modal-header">
          
              <h4 class="modal-title">{{ 'ForgetPassword' | translate}}</h4>
              <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
              <div class="container">
                  <div class="">
                      <label for="txtUserEmail" class=" control-label">{{ 'Email' | translate}}</label>
                      <div class="">
                          <input type="email" class="form-control" name="txtUserEmail" id="txtUserEmail" #userEmail  required>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" [disabled]="!userEmail" class="btn btn-primary" (click)="sendPasswordToUserEmail(userEmail.value)" data-dismiss="modal">{{ 'Send' | translate}}</button>
          </div>
      </div>
  </div>
</div>
