import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavBarComponent } from '../nav-bar/nav-bar.component'
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent,  TranslateService } from '@ngx-translate/core';

import { Meta} from "@angular/platform-browser";
@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: ['./single-blog.component.css']
})
export class SingleBlogComponent implements OnInit {

  id: any;
  blogs: any[];
  arabicBlogs: any[];
  lang: any;

  constructor(private route: ActivatedRoute, private myNav: NavBarComponent,private sharedService:SharedService , private meta:Meta,public translate :TranslateService) {


    this.blogs = [
      {
        id: 1,
        img: "/assets/images/card1.png",
        title: "Five reasons why ConClinic is your first choice for managing your clinic",
        text: "Paper methods have become one of the old and inefficient methods of managing any medical clinic. Papers are more exposed for damage or loss, which means failure to record and plan your clinic’s affairs.",
        text1: "Therefore, it has become necessary and even essential to apply clinic management system for your clinic",
        text2: "in order to simplify and reduce the use of consuming resources such as time, expenses, and staff efforts",
        text3: "But the question is, are these programs available? Or are they easy to be applied and used?",
        text4: "We provide you with all the answers with the ConClinic clinic management system.",
        text5: "What is ConClinic system?",
        text6: "It is a system that helps manage your clinic from A to Z in terms of organizing medical and financial affairs for clinics, hospitals or any medical facility.",
        text7: "What are the tasks performed by the ConClinic system?",
        text8: "1- It provides you with an organized work environment:",
        text9: "The ConClinic system helps you to reserve and schedule patients' appointments and specify the duration of each session. The reservations are shown periodically by day, week or month, with the feature of confirmation or cancellation for each reservation.",
        text10: "2- Providing detailed reports for all your patients:",
        text11: "The ConClinic system is the needed database in your work, as it enables you to record the patient's examination and his previous and current medical reports, which facilitates the process of extracting a comprehensive report of the treatment history for each patient.",
        text12: "3- Eases accounts management:",
        text13: "The ConClinic system is your financial manager, as it enables you to record your financial invoices, whether they are expenses, patient session fees, or any financial transactions, ensuring that there is no error when counting or auditing your accounts.",
        text14: "4- Don't worry about insurance contracts:",
        text15: "The ConClinic system provides you with insurance details, as the system integrates the patient's details with the insurance company, making it easier for you to access the patient's insurance policy and collect the approved bills.",
        text16: "5- ConClinic makes it easier for you to manage the stores of your medical facility:",
        text17: "The ConClinic system makes it easy for you to manage your clinic storage by defining and coding items, making a continuous periodic inventory, and issuing detailed reports for all opening balances.",
        text18: "The ConClinic system is safe and easy to use and apply. The system can be accessed by more than one user in case you want to add some employees to follow up on certain tasks. You do not have to worry about the employee because you are the upper hand who controls the task and those assigned to it.",
        text19: "All these features make the system your right hand in managing your clinic efficiently and effectively.",

      },
      {
        id: 2,
        img: "/assets/images/card2.png",
        title: "Actions that affect your clinic in a bad way!",
        text: "Managing your clinic efficiently is a difficult challenge, but not impossible, and sometimes we may need some guidance so that we do not act in a way that harms the work environment.",
        text1: "In this article, we will tell you about some wrong management actions and it’s solution",
        text2: "First, you must record the patient's medical history:",
        text3: "The patient’s medical history is one of the most important points for the doctor in diagnosing the disease and prescribing appropriate medical treatment, but one of the common mistakes is the doctor’s reliance entirely on recording patients’ visits in paper form, which leads to some problems such as the loss or damage of those papers",
        text4: "Therefore, the solution lies in recording a file for each patient electronically.The ConClinic is a clinic management system that works as a database and helps you in extracting comprehensive reports of the patient’s treatment history and the history of visits as well.",
        text5: "Secondly, review your bills periodically:",
        text6: "Some may get lost between the incoming and outgoing expenses and revenues of the clinic, and some may not calculate the prices of the products or even do not record a list of what is the storage availability, which causes many problems in calculating the clinic’s bills and profits ",
        text7: "Therefore, the ConClinic system provides you with the perfect solution because it helps you record incoming and outgoing invoices and helps you record invoices paid by your customers. It also provides you with a list of the stock to show you what is available and what is not available in your storage. All of this will facilitate the inventory process and calculate the net profits of your clinic efficiently.",
        text8: "Managing your clinic is easier when you use the ConClinic management system",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      },
      {
        id: 3,
        img: "/assets/images/card3.png",
        title: "7 Basic steps to implement a successful management system for your clinic",
        text: "One of the most important steps for the success of your clinic is to apply a good management system, and the application of a good management system you need to follow some basic steps to ensure the greatest benefit",
        text1: "Implementing a management system for your clinic requires preparation and planning, and here we will explain to you the steps to successfully apply and use it within your clinic",
        text2: "1- Determine the goal that you seek through your clinic and search for what helps you achieve this goal",
        text3: "2- Determine the management program that contains tasks that will help you achieve your goal",
        text4: "3- Organize the workflow plan in cooperation with the management program of your choice",
        text5: "4- Create a flexible and achievable schedule",
        text6: "5- Transfer customer data from your old (paper) systems to the new management system",
        text7: "6- Determine interviews with your employees to explain how to use the new system and start distributing tasks to each of them while encouraging them to accept the change to achieve the highest efficiency of the workflow",
        text8: "7- Using the system support team to achieve the highest value of the service provided to you and your clinic",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      },
      {
        id: 4,
        img: "/assets/images/4.png",
        title: "How will the digital transformation process and management systems change in your health facility?",
        text: "Medical management systems have an important role in transforming unsustainable systems into sustainable ones, and providing advanced, less expensive and more effective solutions for management and service delivery, and this in turn will be reflected in your health facility. Therefore, we present you some of what technological trends and data processing systems will change how you run your health facility ",
        text1: "1- Provide healthcare from anywhere:",
        text2: "Adopting technology and management systems helps you provide remote healthcare service management and allows you to monitor your facility and patients without the need to be there in person.",
        text3: "2- Making the most of the data and preserving it:",
        text4: "Healthcare organizations collect huge amounts of data, and big data is relied upon to make better use of it. This helps improve diagnosis and treatment, generate reports, and personalize health services for patients.",
        text5: "3- Practical division of tasks and time:",
        text6: "Management systems enable you to schedule appointments and determine the time period for each service or session. It also helps in distributing tasks to employees, ensuring that you make the most of working hours and ensuring the achievement of your organization's goals.",
        text7: "These are some of the most famous features of the technological trend in the medical sector that have changed the ways of providing health care to patients, and have also contributed to the development and acceleration of detection, provision of consultations, and saving time and effort.",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      } ,
      {
        id: 5,
        img: "/assets/images/5.png",
        title: "What are the most common challenges that you may face in implementing medical management systems?",
        text: "1- Openness and readiness:",
        text1: "Getting ready to implement this system in your clinic may be a challenge, but it is a temporary challenge, because once you know the rules of the system, you will be able to easily use it.",
        text2: "2- Difficulty managing data: ",
        text3: "Digital transformation may become a major challenge because transferring that paper data may take some time, but we guarantee that you will have an easy access to each data and efficiently provide your medical services after that stage.",
        text4: "3- Access to health care data: ",
        text5: "You may face this challenge, especially regarding insurance data, but Conclinic system enables you to integrate patient data with insurance company data to obtain all important information regarding insurance bills and contract details.",
        text6: "4- Privacy: ",
        text7: "With Conclinic system this is not a problem at all! because you are the upper hand and controller in terms of data and the distribution of tasks to employees.",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      }
      ,{
        id: 6,
        img: "/assets/images/6.png",
        title: "Problems you need to avoid  when it comes to scheduling your appointments",
        text: "Clinic management systems helped solve many of the problems that were facing clinics, especially those who still rely on the paper system in management and organization.",
        text1: "We present to you the most common problems of relying on traditional methods for managing your clinic:",
        text2: "The traditional problems of receiving patients are summed up in the poor organization of roles and the occurrence of daily problems with patients and secretaries when they reserve an appointment, as well as the lack of organization in roles and appointments, which results:",
        text3: "\t\t-Long queues",
        text4: "\t\t- Appointments canceled due to angry patients",
        text5: "\t\t- Irregularity of long-term patients in the clinic",
        text6: "\t\t- Negative reactions towards the clinic and the unregulated system",
        text7: "What is the best solution to provide exposure to these problems?",
        text8: "Relying mainly on a strong management system that helps you avoid deficiencies in traditional management through:",
        text9: "\t\t- Organizing patient files electronically",
        text10: "\t\t- Solving clinic financial accounts problems by registering them",
        text11: "\t\t- Organizing patients' reception and appointments",
        text12: "\t\t- Merging the work of medical clinics with private insurance companies",
        text13: "\t\t- Organizing secretarial work",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      }
      ,{
        id: 7,
        img: "/assets/images/7.jpg",
        title: "How Conclinic system helps you to increase your customer base?",
        text: "Who doesn’t want to increase the profit of their medical facility? the best way to do that is to increase the number of your clients until you achieve sufficient profit to run your business safely. So the most important question is; How can you increase the number of your customers and gain their trust? ",
        text1: "Providing a distinguished service to your customers that enables you to gain their trust starts with choosing a program that organizes your tasks and the database of customers, if they are not collected in an organized manner and in a safe place, it will cause many problems and make the sales process more difficult",
        text2: "Here are four important points to increase your clinic's profits:",
        text3: "\t\t1- Record your customer information in one secure place.",
        text4: "\t\t\t The Conclinic system allows you to create a file for each customer that contains all his data and all the transactions that took place with him, whether from the sales team or the customer service team. They also enable them to add comments on the transactions that took place with him, which allows them to quickly access the data in an organized manner and not to waste time and effort.",
        text5: "\t\t2- Be alert to new opportunities...",
        text6: "\t\t\t New customers are a good potential that you should not lose. Therefore, the Conclinic system enables you to save an unlimited number of customer data in an organized manner while monitoring the results of advertising campaigns and reaching potential customers who interacted with the campaigns, which helps you identify your customers, find new opportunities for sales, and determine future offers for existing customers and special offers With potential customers, and through the accurate statistics and reports provided by the system, you can predict the outcome of each transaction, which helps you in the development of your medical facility.",
        text7: "\t\t3- Make information easily accessible.",
        text8: "\t\t\t One of the most common problems facing the salesman, especially the one who meets customers outside the company, is the presence of all customer data and a schedule of customer appointments in one place that is easy to access, but with the availability of the Konlink system that allows you to access all registered data and details, and you can also add or Amending customer data and adding details and notes to help you quickly respond to customer questions.",
        text9: "\t\t4- Maintain your relationship with your customers...",
        text10: "\t\t\t Customer satisfaction should always come at the forefront of your organization's attention, the Konlink system allows you to quickly solve customer problems because all data is in one place and the presence of a quick search tool enables you to access the data you need in the fastest time, in addition to that the system enables you to keep necessary appointments and send Emails to each customer, which is one of the most important things that you must achieve.",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      }
      ,{
        id: 8,
        img: "/assets/images/8.jpg",
        title: "Signs that indicated your need for a strong management system",
        text: "Systems and programs specialized in managing medical facilities  help in the growth and development, and in case you are hesitant whether you need a management system or not, we will tell you some points through which you can determine your need for a business management program within your facility:",
        text1: "\t\t1- Your management system is slow and inefficient",
        text2: "\t\t\t A slow system reduces the efficiency of your clinic in completing its important functions related to paper transactions or customer information and organizing tasks, so you must move quickly towards adopting a new system that manages your clinic efficiently so as not to lose your information and clients.",
        text3: "\t\t2- Preparing reports manually",
        text4: "\t\t\t Manual reports are more vulnerable to damage and loss, so do not delay in adopting a system that makes all information available at your fingertips at any time and always in a safe place.",
        text5: "\t\t3- Your company is constantly growing",
        text6: "\t\t\t The growth of your company means the necessary need for a system that accommodates the amount of important information as necessary to arrange everything related to customer matters, employee tasks, and managing your clinics if you are in a continuous expansion that requires the necessary control of these different facilities.",
        text7: "",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      }
      ,{
        id: 9,
        img: "/assets/images/9.jpg",
        title: "3 important Points that help you build a strong relationship with your customers",
        text: "The value of your company increases with the increase in the number of your permanent and regular customers, and as usual obtaining a new customer or maintaining existing ones is difficult and expensive, ",
        text1: "we offer you some tips that will help you maintain your customers",
        text2: "\t\t1- Understanding customer behavior",
        text3: "\t\t\t Understanding the behavior and interests of your customers helps you build sound marketing plans and helps you determine the method of communication between you and them according to their interests and behavior by recording all customer data and all customer interactions with the market and with your commodity or service provided, which enables you to form detailed reports on your customers' behavior.",
        text4: "\t\t2- Interaction with customers",
        text5: "\t\t\t Constant communication and interaction with clients in more than one way, whether by calls or through e-mails, social media, or your own website, through which you can reach all your clients. Messages with offers and topics that interest them.",
        text6: "\t\t3- VIP Customers",
        text7: "\t\t\t If you are able to record all the data of your customers, it will help you to know them and reach them personally, which makes the customer feel important to you, and in turn it makes you evok in the mind of your customers.",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: ""
      }
     
    ]


    this.arabicBlogs = [
      {
        id: 1,
        img: "/assets/images/card1.png",
        title: "خمس أسباب تجعل نظام كونكلنك هو اختيارك الأول لإدارة عيادتك",
        text: "الأساليب الورقية أصبحت من الطرق القديمة والغير فعالة في ادارة اي عيادة طبية، لان الورقيات قد تتعرض للتلف او الضياع ما يسبب قصور في تسجيل وتخطيط امور عيادتك",
        text1: "لذلك أصبح من الضروري بل والأساسي أن تطبق في عيادتك البرامج الخاصة بإدارة الأعمال مثل برنامج تخطيط موارد المؤسسة ERP",
        text2: "برامج التخطيط لعيادتك تساعدك في تبسيط وتوفير العديد من مصادر الشركة المستهلكة بطريقة غير فعالة مثل الوقت والمصروفات ومجهودات العاملين  ",
        text3: "ولكن يبقى السؤال الأكبر هل هذه البرامج متوفرة ؟ أو هل هي سهلة التطبيق والاستخدام؟  وما هي القيمة الفعلية المستفادة؟ ",
        text4: "نقدم لك كل الاجابات مع نظام إدارة الموارد كونكلنك",
        text5: "ما هو نظام كونكلنك؟",
        text6: "هو نظام يساعد في إدارة منشأتك من الألف للياء من حيث تنظيم الإدارة الطبية والمالية داخل العيادات و المستشفيات أو المراكز الطبية المختلفة",
        text7: "ما هي المهام التي يقوم بها نظام كونكلنك؟",
        text8: " ١- يوفر لك بيئة عمل ذات حجز مُنظم ",
        text9: "يساعدك نظام كونكلينك على تسجيل وجدولة مواعيد المرضى عن طريق حجز المواعيد لكل مريض وتحديد مدة كل جلسة منهم ويتم اظهار الحجوزات بشكل دوري باليوم أو الأسبوع أو الشهر مع خاصية التأكيد أو الإلغاء لكل حجز.",
        text10: "٢- توفير تقارير مُسجلة لكل مرضاك",
        text11: "نظام كونكلينك هو قاعدة البيانات الأساسية في عملك، حيث يُمَكنك من تسجيل كشف المريض وتقاريره الطبية السابقة والحالية مما يسهل عليك عملية استخراج تقرير شامل للتاريخ العلاجي لكل مريض.",
        text12: "٣- يسهل عليك إدارة الحسابات",
        text13: "نظام كونكلينك هو مديرك المالي حيث يُمكنك من تسجيل فواتيرك المالية سواء كانت مصروفات أو تحصيل رسوم كشوفات أو أي تعاملات مالية، مما يضمن لك عدم وجود أي خطأ عند الجرد أو مراجعة حساباتك. ",
        text14: "٤- لا تقلق بشأن التعاقدات التأمينية",
        text15: "نظام كونكلينك يوفر عليك التفاصيل التأمينية، حيث أن النظام يدمج تفاصيل المريض مع شركة التأمين مما يسهل عليك الوصول  للبوليصة التأمينية الخاصة بالمريض وتسجيل الفواتير المصدق عليها.",
        text16: "٥- يسهل عليك إدارة مخازن منشأتك الطبية",
        text17: "نظام كونكلينك يسهل عليك إدارة مخازن المنشأة من خلال تعريف الأصناف وتكويدها وعمل جرد دوري مستمر وإصدار تقارير مُفَصلة لكل الأرصدة الافتتاحية. ",
        text18: "نظام كونكلينك آمن وسهل الاستخدام والتطبيق،  النظام قابل لدخول أكثر من مستخدم في حالة ان اردت اضافة بعض الموظفين لمتابعة مهام معينة، ولا داعي للقلق بشأن الموظف لانك المتحكم الأول والأخير في صلاحيات المهمة والمكلفين بها",
        text19: "كل تلك المميزات تجعل من النظام هو يدك اليمني في إدارة عيادتك بكفاءة عالية",

      },
      {
        id: 2,
        img: "/assets/images/card2.png",
        title: "تصرفات يجب عليك التخلص منها لأنها قد تضر بعيادتك  ",
        text: "إدارة عيادتك بكفاءة هو تحدي صعب ولكن ليس مستحيل، وأحيانا قد نحتاج لبعض الإرشاد كي لا نقع في تصرفات تضر ببيئة العمل ",
        text1: "في هذه المقالة سنخبرك ببعض التصرفات المضرة بعيادتك، وسنوفر لك الحلول ايضاً ",
        text2: "اولاً يجب عليك تسجيل التاريخ العلاجي للمريض:",
        text3: "التاريخ العلاجي للمريض هو أحد أهم النقاط بالنسبة للطبيب في تشخيص المرض والعلاج الطبي المناسب، ولكن من احد الاخطاء الشائعة هو اعتماد الطبيب بشكل كلي على تسجيل زيارات المرضى بشكل ورقي مما يؤدي لبعض المشاكل مثل ضياع أو تلف تلك الورقيات",
        text4: "لذلك الحل يكمن في تسجيل ملف لكل مريض ولكن بشكل الكتروني، نظام كونكلنك لإدارة العيادات يعمل كقاعدة بيانات أساسية ويساعدك في استخراج تقارير شاملة لتاريخ العلاجي العلاجي للمريض وتاريخ الزيارات ايضاً",
        text5: "ثانيا راجع فواتيرك بشكل دوري :",
        text6: "قد يتوه البعض بين المصروفات والإيرادات الصادرة والواردة من العيادة، وقد لا يحسب البعض أسعار المنتجات ولا يسجل قائمة بالمتوفر في المخازن، مما يسبب مشاكل كثيرة في حساب فواتير وأرباح العيادة ",
        text7: "لذلك نظام كونكلنك يوفر لك الحل الامثل لانه يساعدك في تسجيل الفواتير الصادرة والواردة ويساعدك في تسجيل الفواتير المدفوعة من قبل عُملائك كما ايضا يوفر لك قائمة المخزون والتي تظهر لك المتوفر والمغير متوفر في المخزون ، كل ذلك سيسهل عليك عملية الجرد وحساب صافي أرباح عيادتك.",
        text8: "إدارة عيادتك أسهل عند استخدام نظام إدارة كونكلنك",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 3,
        img: "/assets/images/card3.png",
        title: "٧ خطوات أساسية لتطبيق نظام إدارة ناجح لعيادتك ",
        text: "من أهم خطوات نجاح عيادتك هو تطبيق نظام ادارة جيد، وتطبيق نظام ادارة جيد تحتاج لاتباع بعض الخطوات الأساسية لتضمن أكبر استفادة ",
        text1: "تنفيذ نظام إدارة لعيادتك يتطلب الإعداد والتخطيط , وهنا سوف نشرح لك خطوات تنفيذ نظام ERP داخل عيادتك  بنجاح        ",
        text2: "١- تحديد الهدف الذي تسعى اليه من خلال عيادتك والبحث عن ما يساعدك في تحقيق هذا الهدف        ",
        text3: "٢- تحديد  برنامج الإدارة الذي يحتوي على برامج مهمة تساعدك في تحقيق هدفك ",
        text4: "٣- تنظيم خطة سير العمل بالتعاون مع برنامج الإدارة الذي اختارته ",
        text5: "٤- إنشاء جدول زمني مرن وقابل للتحقيق",
        text6: "٥- نقل بيانات العملاء من انظمتك القديمة (الورقية) الي نظام الإدارة الجديد",
        text7: "٦- تحديد مقابلات مع موظفيك لشرح كيفية استخدام النظام الجديد والبدء بتوزيع المهام على كلاً منهم مع تشجيعهم لقبول التغيير لتحقيق أعلى كفاءة لسير العمل",
        text8: "٧- الاستعانة بفريق الدعم للاستفادة القصوى من الخدمة المقدمة لك و لعيادتك",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 4,
        img: "/assets/images/4.png",
        title: "كيف ستغير عملية التحول الرقمي وأنظمة الإدارة في منشأتك الصحية",
        text: "نُظُم إدارة المنشآت الطبية في القطاع الطبي لها دور مهم في تحويل الأنظمة الغير مستدامة إلى أنظمة مستدامة، وتوفير حلول متطورة وأقل تكلفة وأكثر فعالية للإدارة وتقديم الخدمات، وهذا بدوره سوف ينعكس منشأتك الصحية",
        text1: " ١- تقديم الرعاية الصحية من أي مكان:",
        text2: "يساعدك تبني التكنولوجيا ونظم الإدارة في تقديم إدارة خدمات الرعاية الصحية عن بعد  مما يسمح لك بمراقبة منشأتك ومرضاك بدون الحاجة للتواجد بشخصك",
        text3: "٢- الإستفادة القصوى من البيانات والحفاظ عليها: ",
        text4: "تجمع مؤسسات الرعاية الصحية كميات هائلة من البيانات، ويتم الاعتماد على البيانات الضخمة للاستفادة منها بشكل أفضل. يساعد ذلك في تحسين التشخيص والعلاج، وإستخراج التقارير، وإضفاء الطابع الشخصي على الخدمات الصحية للمرضى.",
        text5: "٣- تقسيم المهام والوقت بشكل عملي:",
        text6: "نُظُم الإدارة تمكنك من جدولة المواعيد وتحديد المدة الزمنية لكل خدمة كما يساعد في توزيع المهام على الموظفين مما يضمن لك الإستفادة القصوى من ساعات العمل وضمان تحقيق أهداف منشأتك.",
        text7: "هذه هي بعض أشهر المميزات في الإتجاه التكنولوچي  في القطاع الطبي التي غيرت من طرق تقديم الرعاية الصحية للمرضى كما ساهمت أيضاً في تطوير وتسريع الكشف وتقديم الاستشارات وتوفير الوقت والجهد.",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 5,
        img: "/assets/images/5.png",
        title: "ما هي أهم التحديات التي قد تواجهك في تطبيق نُظم الإدارة لمنشأتك الطبية",
        text: "الاستعداد التنظيمي: ",
        text1: " الاستعداد لتطبيق هذا النظام في عيادتك قد يكون تحدي ولكن تحدي مؤقت لأنه بمجرد تعرفك على قواعد النظام ستصبح قادر على استخدامه بكل سهولة.",
        text2: "صعوبة إدارة البيانات:",
        text3: "التحول الرقمي قد يصبح تحدي كبير لان نقل تلك البيانات الورقية قد يستهلك بعض الوقت ولكن نضمن لك الحصول على النظام والفعالية في تقديم خدماتك الطبية بعد تلك المرحلة.",
        text4: "الوصول إلى بيانات الرعاية الصحية:",
        text5: "قد يواجهك ذلك التحدي خصوصا فيما يخص البيانات التأمينية ولكن نظام كونكلنك يقدم لك خاصية دمج بيانات المريض مع بيانات شركة التأمين لتحصل علي كل المعلومات المهمة فيما يخص الفواتير التأمينية وتفاصيل التعاقد. ",
        text6: "الخصوصية: ",
        text7: "ولكن مع كونكلنك هذه ليست مشكلة ابدا لانك المتحكم الأول والأخير فيما يخص البيانات وتوزيع المهام على الموظفين. ",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 6,
        img: "/assets/images/6.png",
        title: "مشاكل يجب عليك تجنبها في جدولة مواعيد عيادتك",
        text: "نقدم لك أهم مشاكل الاعتماد على الطرق التقليدية في إدارة عيادتك:",
        text1: "تتلخص مشاكل استقبال المرضى التقليدية في سوء تنظيم الأدوار وحدوث مشكلات يوميا مع المرضى والسكرتارية عند دخولهم للطبيب، وايضا عدم التنظيم في الأدوار والمواعيد مما يترتب عليها:",
        text2: "\t\t- صفوف الانتظار الطويلة ",
        text3: "\t\t- إلغاء المواعيد بسبب غضب المرضى",
        text4: "\t\t- عدم انتظام المرضى على المدى الطويل فى العيادة",
        text5: "\t\t- ردود أفعال سلبية تجاه العيادة والسيستم الغير منظم",
        text6: "ما هو الحل الأمثل لتوفير التعرض لتلك المشاكل؟",
        text7: "الاعتماد بشكل أساسى على نظام إدارة قوي يساعدك على تجنب القصور في الإدارة التقليدية من خلال: ",
        text8: "\t\t- تنظيم ملفات المرضى إلكترونيا ",
        text9: "\t\t-  حل مشاكل حسابات العيادة من خلال تسجيلها ",
        text10: "\t\t- تنظيم استقبال المرضى ومواعيدهم ",
        text11: "\t\t- دمج عمل العيادات الطبية مع الشركات الخاصة بالتأمين",
        text12: "\t\t- تنظيم عمل السكرتارية ",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 7,
        img: "/assets/images/7.jpg",
        title: "كيف يُمكنك نظام كونكلنك من زيادة عدد عملائك؟",
        text: "من المؤكد انك تريد زيادة ربح منشأتك الطبية، والطريق الأمثل لذلك هو زيادة عدد عملائك حتي تحقق الربح الكافي لادارة عملك. ولكن يبقى السؤال الأهم… كفي يمكنك زيادة عدد عملائك ومسب ثقتهم؟ هل هناك ما يجب عليك الأخذ به في عين الإعتبار؟ هذا ما ستعرفه الآن",
        text1: "تقديم خدمة مميزة لعملائك يمكنك من كسب ثقتهم وكسب عملاء جدد , ومن أكثر الأشياء التى تساعدك على ذلك هو اختيار برنامج ينظم لك مهام عملك وجميع التفاصيل الخاصة بالعملاء حيث انه إذا لم يتم تسجيلها بشكل منظم وفي مكان آمن سوف يسبب مشاكل عديدة ويزيد خطوات عملية البيع صعوبة ",
        text2: "اليك اربع نقاط مهمة لزيادة ارباح عيادتك: ",
        text3: "\t\t١- سجل معلومات عملائك في مكان واحد آمن…        ",
        text4: "\n\t\t نظام كونكلينك يتيح لك إنشاء ملف خاص بكل عميل يوجد به جميع البيانات الخاصة به وجميع التعاملات التي تمت معه سواء من فريق المبيعات او فريق خدمة العملاء كما يمكنهم من إضافة تعليقات على التعاملات التي تمت معه ويتيح لهم سرعة الوصول إلى البيانات بطريقة منظمة لعدم اضاعة الوقت والمجهود.",
        text5: "\t\t٢- كن متيقظاً للفرص الجديدة …        ",
        text6: "\n\t\t العملاء الجدد فرص مهمة يجب الا تخسرها لذلك نظام كونكلنك يُمكنك من حفظ عدد غير محدود من بيانات العملاء بطريقة منظمة مع مراقبة نتائج الحملات الاعلانية والوصول للعملاء المحتملين الذين تفاعلوا مع الحملات والتى تساعدك في تحديد عملائك وإيجاد فرص جديدة للبيع وتحديد عروض مستقبلية خاصة بالعملاء الحاليين وعروض خاصة بالعملاء المحتملين ومن خلال الإحصاءات والتقارير الدقيقة التى يوفرها لك النظام يمكنك توقع نتيجة كل صفقة مما يساعدك في تنمية منشأتك الطبية.",
        text7: "\t\t ٣- اجعل المعلومات سهل الوصول اليها …        ",
        text8: "\n\t\t من أكثر المشاكل التي تقابل رجل المبيعات خاصة الذي يقوم بمقابلة العملاء خارج الشركة هو وجود جميع البيانات الخاصة بالعملاء و جدول بمواعيد العملاء في مكان واحد يسهل الوصول إليه ولكن مع إتاحة نظام كونكلنك الذي يسمح لك بالوصول إلى جميع البيانات والتفاصيل المسجلة ، كما يمكنك من اضافة او التعديل في بيانات العميل واضافة تفاصيل وملاحظات ليساعدك في سرعة الرد على أسئلة العميل.",
        text9: "\t\t٤- حافظ على علاقتك بعملائك …        ",
        text10: "\n\t\t إرضاء العميل يجب أن يأتي دائما في مقدمة اهتمام منشأتك،  نظام كونكلنك يتيح لك سرعة حل مشاكل العملاء لأن جميع البيانات تكون في مكان واحد ووجود اداة بحث سريعة تمكنك من الوصول إلى البيانات التي تحتاجها في اسرع وقت بالاضافة الى انه يمكنك النظام من الاحتفاظ بالمواعيد الهامة وارسال رسائل الكترونية لكل عميل وهي من أهم الأشياء التي يجب أن تحققها.",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 8,
        img: "/assets/images/8.jpg",
        title: "علامات لو تواجدت في منشأتك الطبية، اذاً انت بحاجة لنظام إدارة قوي",
        text: "الانظمة والبرامج المتخصصة في إدارة العيادات تساعد في النمو والتطوير من أمنشأتك الطبية,  وفي حالة اذا كنت متردد هل تحتاج الى نظام إدارة أم لا ",
        text1: "فسوف نخبرك بعض النقاط التي تستطيع من خلالهم تحديد إحتياجك لبرنامج إدارة الاعمال داخل منشأتك :",
        text2: "\t\t١- نظام ادارتك بطئ وغير كوفئ        ",
        text3: "\n\t\t النظام البطئ يقلل من كفاءة عيادتك في اتمام وظائفها المهمة سواد الخاصة بالمعاملات الورقية او الخاصة بمعلومات العميل وتنظيم المهام، لذلك يجب عليك التحرك سريعا في اتجاه تبني نظام جديد يدير عيادتك بشكل كوفئ حتي لا تخسر ملعوماتك وعملائك.",
        text4: "\t\t٢- إعداد التقارير يدوياً ",
        text5: "\n\t\t التقارير اليدوية اكثر عرضة للتلف والضياع، لذلك لا تتأخر في تبني نظام يجعل المعلومات كلها متاحة تحت يدك في اي وقت ويجعلها في مكان آمن دائماً.",
        text6: "\t\t٣- شركتك في نمو مستمر ",
        text7: "\n\t\t نمو شركتك يعني الحاجة الضرورية لنظام يستعيب كم المعلومات المهم كما هو ضروري لترتيب كل ما يخص آمور العملاء ومهام الموظفين وادارة عياداتك اذا كنت في توسع مستمر يستدعي الرقابة الضرورية لتلك المنشأت المختلفة.",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      },
      {
        id: 9,
        img: "/assets/images/9.jpg",
        title: "٣ نقاط تساعدك على بناء علاقة قوية بعملائك",
        text: "قيمة شركتك تزداد كل ما زاد عدد عملائك الدائمين والمنتظمين، ولان عادة الحصول عل عميل جديد او الحفاظ على العلماء الحاليين أمر صعب ومكلف، نقدم لك بعض النصائح التي ستساعدك في الحفاظ على عملائك",
        text1: "\t\t١- فهم سلوك العملاء",
        text2: "\n\t\t فهم سلوك واهتمامات عملائك يساعدك في بناء خطط تسويقية سليمة كما يساعدك في تحديد طريقة التواصل بينك وبينهم حسب اهتماماتهم وسلوكهم عن طريق تسجيل كل البيانات الخاصة بالعملاء وكل تفاعلات العملاء مع السوق ومع سلعتك او الخدمة المقدمةمما يمكنك من تكوين تقارير تفصيلية عن سلوك عملائك.",
        text3: "\t\t٢- التفاعل مع العملاء",
        text4: "\n\t\t التواصل والتفاعل الدائم مع العملاء باكثر من طريقة سواء بالمكالمات او عن طريق رسائل البريد الالكتروني او السوشيال ميديا او الموقع الخاص بك حيث يمكنك من خلاله الوصول لكل عملائك ومن الطرق الحديثة والموفره هي برامج ادارة العيادات حيث تساعدك على التواصل مع العملاء وتسجيل بياناتهم مع الاستمرارية في ارسال رسائل بالعروض والمواضيع التي تهمهم.",
        text5: "\t\t٣- عملاء مميزون",
        text6: "\n\t\t اذا استطعت تسجيل كل بيانات عملائك سيمكنك ذبك من معرفتهم والتوصل لهم بشكل شخصي مما يجعل العميل يشعر باهميته بالنسبه لك مما يجعلك تظهر في المقام الاول في ذهن عملائك.",
        text7: "",
        text8: "",
        text9: "",
        text10: "",
        text11: "",
        text12: "",
        text13: "",
        text14: "",
        text15: "",
        text16: "",
        text17: "",
        text18: "",
        text19: "",
      }
    ]


    this.lang = this.myNav.myLang;
    console.log(this.lang);
    console.log('id :' + this.id)
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('Blog'+(this.id+1)+'Description')}
       ]);
      console.log('id :' + this.id)
    this.sharedService.setTitle(this.translate.instant('Blog'+(this.id+1)+'Title'))
  })
  }

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];
    this.id -= 1;
    this.sharedService.setTitle(this.translate.instant('Blog'+(this.id+1)+'Title'))
    this.meta.addTags([
      {name: 'description', content: this.translate.instant('Blog'+this.id+'Description')}
    ]);
   
  }

}
