import { Component, OnInit } from '@angular/core';
import { Meta} from "@angular/platform-browser";
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent,  TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private sharedService:SharedService , private meta:Meta,public translate :TranslateService ) { 
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('AboutDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('AboutTitle'))
  })
  }

 

  ngOnInit(): void {
    this.meta.addTags([
      {name: 'description', content: this.translate.instant('AboutDescription')}
    ]);
    this.sharedService.setTitle(this.translate.instant('AboutTitle'))

  }
}
