import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LocalStorageService } from 'ng2-webstorage';
import { ToastrService } from 'ngx-toastr';
import { FormsModule, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserTypeEnum } from 'src/app/security/shared/user-type.enum';
import { remoteServerUrl } from 'src/app/app.config';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: [
    '../user/user.component.css', './add-user.component.css']
})
export class AddUserComponent implements OnInit {
  LandingForm: FormGroup;
  model: any = {};
  active = true;
  showProgress = false;
  userTypeEnum: UserTypeEnum = new UserTypeEnum();
  userEmail: string = '';
  serverUrl: string = remoteServerUrl.replace("api/", "");
  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private router: Router,
    private sharedService: SharedService) {
    this.LandingForm = this.fb.group({
      Email: ['', Validators.required],
      Phone: ['', Validators.required],
      ClinicName: ['', Validators.required],
      FName: ['', Validators.required],
      LName: ['', Validators.required]
    })

  }

  ngOnInit(): void {
  }
  AddUserLandingPage(): void {

    let thisComp = this;
    if (!this.LandingForm.valid) {
      this.toastr.error("Please complete the required fields", '');

    } else {

      this.model.Email = this.LandingForm.controls['Email'].value;
      this.model.Phone = this.LandingForm.controls['Phone'].value;
      this.model.ClinicName = this.LandingForm.controls['ClinicName'].value;
      let fname = this.LandingForm.controls['FName'].value;
      let lName = this.LandingForm.controls['LName'].value;
      this.model.Name = fname + ' ' + lName;
      console.log(this.model)
      this.sharedService.SaveLandingPageUser(this.model)
        .subscribe(
          function (result: any) {
            thisComp.toastr.success("Saved Successfully , We will Contact you soon", '');
            setTimeout(function () { thisComp.router.navigate(['/']) }, 5000)
          },
          function (res: any) {
            thisComp.toastr.error(res.error, '');


          },
          function () {
            thisComp.showProgress = false;
          }
        );

    }
  }
}
