import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent,  TranslateService } from '@ngx-translate/core';

import { Meta} from "@angular/platform-browser";
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(private sharedService:SharedService , private meta:Meta,public translate :TranslateService) {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('FeaturesDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('FeaturesTitle'))
  })
   }
  ngOnInit(): void {
    
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('FeaturesDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('FeaturesTitle'))

  }

}
