import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ng2-webstorage';
import { _throw } from 'rxjs/observable/throw';
import 'rxjs/add/operator/catch';

/**
 * Intercepts the HTTP responses, and in case that an error/exception is thrown, handles it
 * and extract the relevant information of it.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
   
    /**
     * 
     * Intercepts an outgoing HTTP request, executes it and handles any error that could be triggered in execution.
     * @see HttpInterceptor
     * @param req the outgoing HTTP request
     * @param next a HTTP request handler
     */
    constructor(public localStorage: LocalStorageService ,  private toastr: ToastrService) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .catch(errorResponse => {
                let errMsg: string;
                if (errorResponse instanceof HttpErrorResponse) {
                    const err = errorResponse.message || JSON.stringify(errorResponse.error);
                    errMsg = `${errorResponse.status} - ${errorResponse.statusText || ''} Details: ${err}`;
                } else {
                    errMsg = errorResponse.message ? errorResponse.message : errorResponse.toString();
                }
                if (errorResponse.status == "401") {
                    //navigate to current URL
                   // this.router.navigate(['/shared/unauthorized']);
                   this.localStorage.clear("AccessToken");
                this.localStorage.clear("UserName");
                this.localStorage.clear("UserType");
                this.localStorage.clear("ClinicID");
                this.localStorage.clear("userId");
                this.localStorage.clear("profileImagePath");
                // this.router.navigate(['/layout-login/doctor']);
                this.toastr.error("You are not authorized ,Please Login again", '');
                 window.location.href='/layout-login/doctor';
                }
                // if((this.localStorage.retrieve("AccessToken") =="" || this.localStorage.retrieve("AccessToken") ==null )){
                //     //console.log(errMsg);
                //     this.toastr.error(errorResponse.message, '');
                //     setTimeout(function(){ window.location.href='./layout-login/doctor'; }, 5000);
                //    // window.location.href='./layout-login/doctor';
                // }
                   // console.log(errorResponse.error);

                this.toastr.error(errorResponse.error, '');
                 _throw(errMsg);
                return Promise.reject(errorResponse.message || errorResponse);
            });
    }
}

/**
 * Provider POJO for the interceptor
 */
export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};