import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent,  TranslateService } from '@ngx-translate/core';

import { Meta} from "@angular/platform-browser";
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  selectedCardIndex: number = 8;
  constructor(private sharedService:SharedService , private meta:Meta,public translate :TranslateService) { 
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('DepartmentDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('DepartmentsTitle'))
  })
  }

  ngOnInit(): void {
    this.meta.addTags([
      {name: 'description', content: this.translate.instant('DepartmentDescription')}
    ]);
   
 this.sharedService.setTitle(this.translate.instant('DepartmentsTitle'))
  }

  selectCard(cardIndex: number) {
    
    this.selectedCardIndex = cardIndex;
  }


}
