import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { remoteServerUrl } from 'src/app/app.config';
import { AccountService } from 'src/app/security/shared/account.service';
import { SharedService } from 'src/app/shared/shared/shared.service';
import { LangChangeEvent,  TranslateService } from '@ngx-translate/core';

import { Meta} from "@angular/platform-browser";
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm : FormGroup;

  model: any = {};
  serverUrl: string = remoteServerUrl.replace("api/", "");

  constructor(private fb: FormBuilder,public accountService: AccountService,
    private toastr: ToastrService,private sharedService:SharedService , private meta:Meta,public translate :TranslateService) { 
      this.contactForm = this.fb.group({
        Name: [''],
        Email: [''],
        Phone: [''],
        Subject: [''],
        Message: [''],
        // Password: ['', Validators.required]
      })
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.meta.addTags([
           {name: 'description', content: this.translate.instant('ContactsDescription')}
         ]);
        
      this.sharedService.setTitle(this.translate.instant('ContactsTitle'))
    })
    }

  ngOnInit(): void {
  
      this.meta.addTags([
         {name: 'description', content: this.translate.instant('ContactsDescription')}
       ]);
      
    this.sharedService.setTitle(this.translate.instant('ContactsTitle'))
  
  }
  contact(): void {
    
    let thisComp = this;
    this.model.name = this.contactForm.controls['Name'].value;
    this.model.email = this.contactForm.controls['Email'].value;
    this.model.phone = this.contactForm.controls['Phone'].value;
    this.model.subject = this.contactForm.controls['Subject'].value;
    this.model.message = this.contactForm.controls['Message'].value;
    this.accountService.contactUser(this.model)
      .subscribe(
        function (result: any) {
            thisComp.toastr.success("Sent", 'Successfully');
            thisComp.contactForm = thisComp.fb.group({
              Name: [''],
              Email: [''],
              Phone: [''],
              Subject: [''],
              Message: [''],
              // Password: ['', Validators.required]
            })

        },
        function (res: any) {
          thisComp.toastr.error(res.error, '');
        },
        function () {
        }
      );

  }
}
